import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.4_@babel+core@7.26.9_@playwright+test@1.50.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/.pnpm/next@15.0.4_@babel+core@7.26.9_@playwright+test@1.50.1_react-dom@18.3.1_react@18.3.1__react@18.3.1/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager", webpackExports: ["Carousel"] */ "/app/scalis-components/core/carousel/carousel.tsx");
;
import(/* webpackMode: "eager" */ "/app/scalis-components/core/dropdown-menu/dropdown-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Navbar"] */ "/app/scalis-components/landing/components/navbar/navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/scalis-components/landing/components/sections/home/Customers.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/scalis-components/landing/components/sections/home/Hero.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/scalis-components/landing/components/shared/ScrollButton.tsx");
;
import(/* webpackMode: "eager" */ "/app/styles/static.css");
